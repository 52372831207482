import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from '../LoadingOverlay';
import ErrorAlert from '../ErrorAlert';

const LoadState = {
  initial: 0,
  loading: 1,
  complete: 2,
};

class LoadingContainer extends React.Component {
  static propTypes = {
    load: PropTypes.func.isRequired,
    renderInitialContent: PropTypes.func,
    renderLoadingIndicator: PropTypes.func,
    renderError: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadEnd: PropTypes.func,
    loadingIndicatorDelay: PropTypes.number,
  };

  static defaultProps = {
    renderInitialContent: () => '',
    renderLoadingIndicator: () => <LoadingOverlay />,
    renderError: error => <ErrorAlert errorMsg={error.message} />,
    loadingIndicatorDelay: 500,
  };

  state = {
    loadState: LoadState.initial,
    loadError: null,
    result: null,
  };

  async load() {
    const state = {
      loadState: LoadState.initial,
      loadError: null,
      result: null,
    };
    // Clone state object when calling setState.
    // Otherwise, mutating the state object changes the state.
    const applyState = () => this.setState({ ...state });

    function _tryCallEventHandler(handler, ...args) {
      try {
        if (handler) handler(...args);
      } catch (error) {
        state.loadError = error;
        applyState();
      }
    }

    const loadingIndicatorDelay = this.props.loadingIndicatorDelay;
    applyState();

    let delayTimeout;
    try {
      state.loadState = LoadState.loading;

      if (loadingIndicatorDelay > 0) {
        // Show loading indicator after delay.  If the load is already done, then the indicator is
        // never shown, since loadState would already be complete
        delayTimeout = setTimeout(() => {
          applyState();
        }, loadingIndicatorDelay);
      } else {
        applyState();
      }

      _tryCallEventHandler(this.props.onLoadStart);
      state.result = await this.props.load();
    } catch (error) {
      state.loadError = error;
      console.error(error); // eslint-disable-line no-console
    }

    clearTimeout(delayTimeout);
    state.loadState = LoadState.complete;
    _tryCallEventHandler(this.props.onLoadEnd, state.loadError, state.result);
    applyState();
  }

  async componentDidMount() {
    await this.load();
  }

  render() {
    switch (this.state.loadState) {
      case LoadState.initial:
        return this.props.renderInitialContent();
      case LoadState.loading:
        return this.props.renderLoadingIndicator();
      case LoadState.complete:
        return this.state.loadError
          ? this.props.renderError(this.state.loadError)
          : this.props.children(this.state.result);
      default:
        throw new Error('Should never reach here');
    }
  }
}

export default LoadingContainer;
